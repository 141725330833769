var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "white--text text-h5 mb-4",
                  attrs: { color: "secondary" },
                },
                [_vm._v(" Companies Edit ")]
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _vm.companies != null
                    ? _c("v-col", { staticClass: "pa-3" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "Company Name",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.companies.company_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companies, "company_name", $$v)
                                },
                                expression: "companies.company_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "Industry",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.companies.company_industry,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.companies,
                                    "company_industry",
                                    $$v
                                  )
                                },
                                expression: "companies.company_industry",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start" },
                          [
                            _c("v-textarea", {
                              attrs: {
                                label: "Address",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.companies.company_address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.companies,
                                    "company_address",
                                    $$v
                                  )
                                },
                                expression: "companies.company_address",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("v-col", { staticClass: "pa-3" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-start" },
                      [
                        _c("v-text-field", {
                          attrs: { label: "Coupon", dense: "", outlined: "" },
                          model: {
                            value: _vm.companies.company_coupon,
                            callback: function ($$v) {
                              _vm.$set(_vm.companies, "company_coupon", $$v)
                            },
                            expression: "companies.company_coupon",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-start" },
                      [
                        _c("v-text-field", {
                          attrs: { label: "SSM No.", dense: "", outlined: "" },
                          model: {
                            value: _vm.companies.company_ssm,
                            callback: function ($$v) {
                              _vm.$set(_vm.companies, "company_ssm", $$v)
                            },
                            expression: "companies.company_ssm",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-end pa-5" },
                [
                  _c("v-btn", { attrs: { color: "primary", plain: "" } }, [
                    _vm._v(" Cancel "),
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function () {
                          this$1.isPending = true
                        },
                      },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }